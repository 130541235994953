/**
 *  komponenta pro štítky pro produkty
 */
import React, { Fragment, useState } from "react";
import AddEditFederation from "./AddEditFederation";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import Notification from "../../../Library/notification";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { GET_FEDERATIONS, DELETE_FEDERATIONS } from "../Queries/federation";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import edit from "../../../Public/Images/edit.svg";
import NoItems from "../../../GlobalComponents/NoItems";
import Helper from "../../../Library/helper";
import translate from "../../Translations/index";
import SearchInput from "../../General/Components/SearchInput";

const Federations = (props) => {
    const client = useApolloClient();
    //zjistime jestli ma prihlaseny administrator spravna prava
    const helper = new Helper();
    helper.checkAdminRights({ ...props, client }, ["1", "2"]);

    const [searchQuery, setSearchQuery] = useState("");
    const [listVariables, setListVariables] = useState({
        filters: {
            offset: 0,
            limit: 50,
            searchQuery: "",
            countryID: 0,
        },
        lang: translate.locale,
    });

    const [selectedFederations, setSelectedFederations] = useState([]);
    const [showDeleteNotifi, setShowDeleteNotifi] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [selectedFederationID, setSelectedFederationID] = useState("");
    const {
        loading,
        error: getError,
        data: federationListData,
        fetchMore,
        refetch,
    } = useQuery(GET_FEDERATIONS, {
        variables: listVariables,
        fetchPolicy: "cache-and-network",
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toString(), "danger");
        },
    });

    const [deleteFederation, { data, loading: deleteLoading, error: deleteError }] = useMutation(DELETE_FEDERATIONS, {
        update(cache, response) {
            let notify = new Notification();
            notify.setNotification(translate.t("SuccessFullyDeleted"));
            setShowDeleteNotifi(false);
            refetch({ filters: { ...listVariables.filters, limit: federationListData.federations.length } });
        },
        onError(err) {
            const helper = new Helper();
            let notify = new Notification();
            notify.setNotification(!!err.message ? err.message : err.toString(), "danger");
        },
    });

    const openCloseModal = (open, federationID) => {
        setSelectedFederationID(federationID);
        setShowAdd(open);
    };

    /**
     * smazání štítků
     * @param {*} deleteFederations funkce, která posílá data na server
     * @param {*} federationID ID federationu
     */

    const initiateDeleteFederation = (e, federationID) => {
        var checked = e.target.checked;
        var arr = [...selectedFederations];

        if (!checked) {
            for (let i in selectedFederations) {
                if (selectedFederations[i] == federationID) {
                    arr.splice(i, 1);
                }
            }
        } else {
            arr = [federationID, ...arr];
        }

        setSelectedFederations(arr);
    };

    const delFederation = (action) => {
        if (action) {
            let federationIDs = selectedFederations.join(",");

            deleteFederation({
                variables: {
                    federationIDs: federationIDs,
                },
            });
        }
        setShowDeleteNotifi(false);
        //this.setState(INITIAL_STATE);
    };

    const showDelNotifi = () => {
        if (selectedFederations.length > 0) {
            setShowDeleteNotifi(true);
        } else {
            let notify = new Notification();
            notify.setNotification(translate.t("ItemNotSelected"), "danger");
        }
    };

    if (getError || deleteError) {
        var error = getError ? getError : deleteError;
        const helper = new Helper();
        error = "ERROR: " + helper.getApolloErrorText(error);
    }
    return (
        <div id="settings" className="whole-container">
            <div className="row">
                <div className="col-sm one">
                    <div className="card main">
                        <div className="card-header d-flex align-items-center">
                            {translate.t("FederationList")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true, "")}>
                                {translate.t("Add")}
                            </button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>
                                {translate.t("DeleteSelected")}
                            </button>
                        </div>
                        <div className={`d-flex px-3 py-2 bg-light border-bottom align-items-center`}>
                            <div className="form-group">
                                <div className="text-nowrap fs12">{translate.t("Country")}</div>
                                <select
                                    className="form-control form-control-sm"
                                    value={listVariables.filters.countryID}
                                    onChange={(e) => setListVariables({ ...listVariables, filters: { ...listVariables.filters, countryID: e.target.value } })}
                                >
                                    <option value={0}>-- {translate.t("Choose")} --</option>
                                    {federationListData?.allCountries.map((item, index) => (
                                        <option key={index} value={item.countryID}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group ml-auto">
                                <div className="text-nowrap fs12">{translate.t("Search")}</div>
                                <SearchInput
                                    className={"form-control form-control-sm "}
                                    value={searchQuery}
                                    onChange={(text) => {
                                        setListVariables({ ...listVariables, filters: { ...listVariables.filters, searchQuery: text, offset: 0 } });
                                    }}
                                    textChanged={(text) => {
                                        setSearchQuery(text);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="card-body">
                            {!error ? (
                                <div>
                                    <div className="data-list">
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>{translate.t("FederationTitle")}</div>
                                                <div>{translate.t("FederationEmail")}</div>
                                                <div>{translate.t("FederationWeb")}</div>
                                                <div>{translate.t("Sport")}</div>
                                                <div>{translate.t("FederationCountry")}</div>
                                                <div className="text-right">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        <Fragment>
                                            {federationListData?.federations?.map((item, index) => {
                                                var checked = false;
                                                for (let i in selectedFederations) {
                                                    if (selectedFederations[i] == item.federationID) checked = true;
                                                }

                                                return (
                                                    <div key={index} className="data-list-item-content">
                                                        <div>{item.name}</div>
                                                        <div>{item.email}</div>
                                                        <div>{item.web}</div>
                                                        <div>{item?.sport?.title}</div>
                                                        <div>{item?.country?.name}</div>

                                                        <div className="text-right">
                                                            <img onClick={() => openCloseModal(true, item.federationID)} className="edit-icon" src={edit} />
                                                            <input
                                                                className="delete-checked"
                                                                type="checkbox"
                                                                name="deleteFederation[]"
                                                                checked={checked}
                                                                onChange={(e) => initiateDeleteFederation(e, item.federationID)}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Fragment>
                                    </div>
                                    {!loading ? (
                                        <Fragment>
                                            {!loading && (
                                                <>
                                                    {!!federationListData?.federations?.length ? (
                                                        <>
                                                            {federationListData.federations.length < federationListData.federationsCount && (
                                                                <div className="text-center p-5">
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        onClick={() =>
                                                                            fetchMore({
                                                                                variables: {
                                                                                    ...listVariables,
                                                                                    filters: {
                                                                                        ...listVariables.filters,
                                                                                        offset: federationListData.federations.length,
                                                                                    },
                                                                                },
                                                                                updateQuery: (prev, { fetchMoreResult }) => {
                                                                                    if (!fetchMoreResult) return prev;
                                                                                    return Object.assign({}, prev, {
                                                                                        federations: [...prev.federations, ...fetchMoreResult.federations],
                                                                                    });
                                                                                },
                                                                            })
                                                                        }
                                                                    >
                                                                        {" "}
                                                                        {translate.t("LoadMore")}{" "}
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <NoItems text={translate.t("NoItemsAddedYet")} />
                                                    )}
                                                </>
                                            )}
                                        </Fragment>
                                    ) : (
                                        <Loading />
                                    )}
                                </div>
                            ) : (
                                <Fragment>{error && <Error text={error} />}</Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showAdd && (
                <AddEditFederation
                    openCloseModal={openCloseModal}
                    selectedFederationID={selectedFederationID}
                    listVariables={listVariables}
                    updated={() =>
                        refetch({
                            filters: {
                                ...listVariables.filters,
                                limit:
                                    listVariables.filters.limit > federationListData.federations.length
                                        ? listVariables.filters.limit
                                        : federationListData.federations.length,
                            },
                        })
                    }
                />
            )}
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delFederation} />}
        </div>
    );
};

export default Federations;
