/*
    Úvodní layout administrace
*/
import React, { Component } from "react";
import Header from "./Header";
import { Switch, Route } from "react-router-dom";
import withAdminAuthentication from "../Modules/Auth/Components/WithAuthentication";

import AllArticles from "../Modules/Article/Components/AllArticles.js";
import ChangePassword from "../Modules/Auth/Components/ChangePassword";
import Link from "../Modules/Content/Components/Link";
import Athletes from "../Modules/Athlete/Components/Athletes";
import AdminUsers from "../Modules/AdminUser/Components/AdminUsers";
import Competitions from "../Modules/Competition/Components/Competitions";
import CompetitionDetail from "../Modules/Competition/Components/CompetitionDetail";
import AgeCategories from "../Modules/AgeCategory/Components/AgeCategories";
import Ladders from "../Modules/Ladder/Components/Ladders";
import { ADMIN_USER } from "../GlobalQueries/globalQueries.js";
import userKey from "../Public/Images/user_key.svg";
import userGroup from "../Public/Images/user_group.svg";
import content from "../Public/Images/content.svg";
import winners from "../Public/Images/winners.svg";
import doorLighter from "../Public/Images/door_lighter.svg";
import settings from "../Public/Images/cogwheel.svg";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import translate from "../Modules/Translations/index";
import withApollo from "../Library/withApollo.js";
import { ReactNotifications } from "react-notifications-component";
import Organisers from "../Modules/Organiser/Components/Organisers.js";
import Sports from "../Modules/Sport/Components/Sports.js";
import Federations from "../Modules/Federation/Components/Federations.js";
import Clubs from "../Modules/Club/Components/Clubs.js";
import Countries from "../Modules/Country/Components/Countries.js";
import PerformanceCategories from "../Modules/PerformanceCategory/Components/PerformanceCategories.js";
import GlobalDisciplines from "../Modules/GlobalDiscipline/Components/GlobalDisciplines.js";
import RankingPointTables from "../Modules/RankingPoints/Components/RankingPointTables.js";

const INITIAL_MENU = [
    { menuID: 0, show: false },
    { menuID: 1, show: false },
    { menuID: 2, show: false },
    { menuID: 3, show: false },
    { menuID: 4, show: false },
    { menuID: 5, show: false },
];

const INITIAL_STATE = {
    roleID: 0,
    showSubmenu: INITIAL_MENU,
};

class Layout extends Component {
    constructor(props) {
        super(props);
        this.toogleSubmenu = this.toogleSubmenu.bind(this);

        const initialState = INITIAL_STATE;
        if (window.location.href.includes("competitions")) {
            initialState.showSubmenu[0].show = true;
        } else if (window.location.href.includes("sports")) {
            initialState.showSubmenu[1].show = true;
        } else if (window.location.href.includes("content")) {
            initialState.showSubmenu[2].show = true;
        } else if (window.location.href.includes("settings")) {
            initialState.showSubmenu[3].show = true;
        }

        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        const adminUserData = this.props.client.readQuery({ query: ADMIN_USER });
        this.setState({ roleID: adminUserData.adminUser.roleID });
    }
    toogleSubmenu(e, menu, prevent) {
        if (prevent) e.preventDefault();

        var arr = this.state.showSubmenu.map(function (item, index) {
            if (index !== menu) return { ...item, show: false };
            else return { ...item, show: !item.show };
        });

        if (prevent) this.setState({ ...this.state, showSubmenu: arr });
        else this.setState({ ...this.state, showSubmenu: arr, cl: "" });
    }

    render() {
        const { roleID } = this.state;
        return (
            <div className="wholeContent h-100">
                <div id="layout">
                    <Header />
                    <div className="d-flex flex-grow-1">
                        <div className={"sideBar open"}>
                            <ul>
                                {["1", "2", "3"].includes(roleID) && (
                                    <li>
                                        <NavLink
                                            className="d-flex align-items-center"
                                            onClick={(e) => this.toogleSubmenu(e, 0, true)}
                                            activeClassName="active"
                                            to="/competitions"
                                        >
                                            <img src={winners} />
                                            <span>{translate.t("Competition")}</span>
                                            <i className={`las ml-auto ${this.state.showSubmenu[0].show ? "la-angle-up" : "la-angle-down"}`}></i>
                                        </NavLink>
                                        <ul className={this.state.showSubmenu[0].show ? "" : "hidden"}>
                                            <li className="last">
                                                <NavLink exact activeClassName="active" to="/competitions">
                                                    {" "}
                                                    {translate.t("CompetitionList")}
                                                </NavLink>
                                            </li>
                                            <li className="last">
                                                <NavLink exact activeClassName="active" to="/competitions/ladders">
                                                    {" "}
                                                    {translate.t("LadderList")}
                                                </NavLink>
                                            </li>
                                            {["1", "2"].includes(roleID) && (
                                                <li className="last">
                                                    <NavLink exact activeClassName="active" to="/competitions/age-categories">
                                                        {" "}
                                                        {translate.t("AgeCategories")}
                                                    </NavLink>
                                                </li>
                                            )}
                                            {["1", "2"].includes(roleID) && (
                                                <li className="last">
                                                    <NavLink exact activeClassName="active" to="/competitions/performance-categories">
                                                        {" "}
                                                        {translate.t("PerformanceCategories")}
                                                    </NavLink>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )}
                                {["1", "2"].includes(roleID) && (
                                    <li>
                                        <NavLink activeClassName="active" strict to="/admin-user">
                                            <img src={userKey} /> <span>{translate.t("Admins")}</span>
                                        </NavLink>
                                    </li>
                                )}
                                {["1", "2"].includes(roleID) && (
                                    <li>
                                        <NavLink activeClassName="active" strict to="/organisers">
                                            <i className="las la-user-edit"></i> <span>{translate.t("Organisers")}</span>
                                        </NavLink>
                                    </li>
                                )}
                                {["1", "2"].includes(roleID) && (
                                    <li>
                                        <NavLink activeClassName="active" strict to="/federations">
                                            <i className="las la-globe-europe"></i> <span>{translate.t("Federations")}</span>
                                        </NavLink>
                                    </li>
                                )}
                                {["1", "2"].includes(roleID) && (
                                    <li>
                                        <NavLink activeClassName="active" strict to="/clubs">
                                            <i className="las la-users"></i> <span>{translate.t("Clubs")}</span>
                                        </NavLink>
                                    </li>
                                )}
                                {["1", "2"].includes(roleID) && (
                                    <li>
                                        <NavLink
                                            className="d-flex align-items-center"
                                            onClick={(e) => this.toogleSubmenu(e, 1, true)}
                                            activeClassName="active"
                                            to="/sports"
                                        >
                                            <i className="las la-running"></i> <span>{translate.t("Sports")}</span>
                                            <i className={`las ml-auto ${this.state.showSubmenu[1].show ? "la-angle-up" : "la-angle-down"}`}></i>
                                        </NavLink>
                                        <ul className={this.state.showSubmenu[1].show ? "" : "hidden"}>
                                            {["1", "2"].includes(roleID) && (
                                                <li className="last">
                                                    <NavLink exact activeClassName="active" to="/sports/sport-list">
                                                        {" "}
                                                        {translate.t("SportList")}
                                                    </NavLink>
                                                </li>
                                            )}
                                            {["1", "2"].includes(roleID) && (
                                                <li className="last">
                                                    <NavLink exact activeClassName="active" to="/sports/global-disciplines">
                                                        {" "}
                                                        {translate.t("Disciplines")}
                                                    </NavLink>
                                                </li>
                                            )}
                                            {["1", "2"].includes(roleID) && (
                                                <li className="last">
                                                    <NavLink exact activeClassName="active" to="/sports/ranking-point-tables">
                                                        {" "}
                                                        {translate.t("RankingPoints")}
                                                    </NavLink>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )}
                                {["1", "2"].includes(roleID) && (
                                    <li>
                                        <NavLink activeClassName="active" strict to="/athletes">
                                            <img src={userGroup} /> <span>{translate.t("Athletes")}</span>
                                        </NavLink>
                                    </li>
                                )}
                                {["1", "2"].includes(roleID) && (
                                    <li>
                                        <NavLink activeClassName="active" strict to="/countries">
                                            <i className="las la-flag"></i> <span>{translate.t("Countries")}</span>
                                        </NavLink>
                                    </li>
                                )}
                                {["1", "2"].includes(roleID) && (
                                    <li>
                                        <NavLink className="d-flex align-items-center" onClick={(e) => this.toogleSubmenu(e, 2, true)} strict to="/content">
                                            <img src={content} />
                                            <span>{translate.t("Content")}</span>
                                            <i className={`las ml-auto ${this.state.showSubmenu[2].show ? "la-angle-up" : "la-angle-down"}`}></i>
                                        </NavLink>
                                        <ul className={this.state.showSubmenu[2].show ? "" : "hidden"}>
                                            <li className="last">
                                                <NavLink exact activeClassName="active" to="/content/link">
                                                    {" "}
                                                    {translate.t("LinkList")}
                                                </NavLink>
                                            </li>
                                            {false && (
                                                <li className="last">
                                                    <NavLink exact activeClassName="active" to="/content/article">
                                                        {" "}
                                                        {translate.t("ArticleList")}
                                                    </NavLink>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )}
                                <li>
                                    <NavLink
                                        className="d-flex align-items-center"
                                        onClick={(e) => this.toogleSubmenu(e, 3, true)}
                                        activeClassName="active"
                                        to="/settings"
                                    >
                                        <img src={settings} />
                                        <span>{translate.t("Settings")}</span>
                                        <i className={`las ml-auto ${this.state.showSubmenu[3].show ? "la-angle-up" : "la-angle-down"}`}></i>
                                    </NavLink>
                                    <ul className={this.state.showSubmenu[3].show ? "" : "hidden"}>
                                        <li className="last">
                                            <NavLink activeClassName="active" to="/settings/change-password">
                                                {" "}
                                                {translate.t("ChangePwd")}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a
                                        className="cursor-pointer"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.logout(e, this.props.client);
                                        }}
                                    >
                                        <img src={doorLighter} /> <span>{translate.t("LogOut")}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <Switch>
                            <Route exact path="/admin-user" component={AdminUsers}></Route>
                            <Route exact path="/content/link" component={Link}></Route>
                            <Route exact path="/athletes" component={Athletes}></Route>
                            <Route exact path="/competitions" component={Competitions}></Route>
                            <Route exact path="/organisers" component={Organisers}></Route>
                            <Route exact path="/federations" component={Federations}></Route>
                            <Route exact path="/clubs" component={Clubs}></Route>
                            <Route exact path="/countries" component={Countries}></Route>
                            <Route exact path="/sports/sport-list" component={Sports}></Route>
                            <Route path="/competition/:competitionHashID" component={CompetitionDetail}></Route>
                            <Route path="/competitions/ladders" component={Ladders}></Route>
                            <Route exact path="/competitions/age-categories" component={AgeCategories}></Route>
                            <Route exact path="/competitions/performance-categories" component={PerformanceCategories}></Route>
                            <Route exact path="/sports/global-disciplines" component={GlobalDisciplines}></Route>
                            <Route exact path="/sports/ranking-point-tables" component={RankingPointTables}></Route>
                            <Route exact path="/content/article" component={AllArticles}></Route>
                            <Route exact path="/settings/change-password" component={ChangePassword}></Route>
                        </Switch>
                    </div>
                </div>
                <ReactNotifications />
            </div>
        );
    }
}

export default withRouter(withApollo(withAdminAuthentication(Layout)));
