/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from "graphql-tag";

/**
 * add/edit federation
 */
export const ADD_EDIT_FEDERATION = gql`
    mutation AddEditFederation($addEditData: AddEditFederationDataInput) {
        addEditFederation(addEditData: $addEditData) {
            hashID
            name
        }
    }
`;

/**
 *  Data federace
 */

export const GET_FEDERATION = gql`
    query Federation($federationID: ID, $lang: String) {
        federation(federationID: $federationID) {
            federationID
            sportID
            hashID
            name
            email
            web
            countryID
            logo
        }
        allCountries {
            countryID
            name
        }
        sports(filters: { lang: $lang }) {
            sportID
            title
        }
    }
`;
export const GET_FEDERATION_PHOTOS = gql`
    query Federation($federationID: ID) {
        federation(federationID: $federationID) {
            logo
        }
    }
`;

/**
 *  Seznam veškerých federací
 */
export const GET_FEDERATIONS = gql`
    query Federations($filters: FederationsFilters, $lang: String) {
        federations(filters: $filters) {
            hashID
            federationID
            sportID
            countryID
            name
            email
            web
            country {
                name
            }
            sport(lang: $lang) {
                title
            }
        }

        federationsCount(filters: $filters)

        allCountries {
            countryID
            name
        }
    }
`;

/**
 * Smazání federací.
 */
export const DELETE_FEDERATIONS = gql`
    mutation deleteFederations($federationIDs: ID!) {
        deleteFederations(federationIDs: $federationIDs)
    }
`;
/**
 * Úprava fotek federace
 */
export const EDIT_FEDERATION_PHOTOS = gql`
    mutation EditFederationPhotos($federationID: ID, $logo: Upload, $logoURL: String) {
        editFederationPhotos(federationID: $federationID, logo: $logo, logoURL: $logoURL)
    }
`;
