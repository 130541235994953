let graphqlServerUrl = "https://server.urbiathlon.com/graphql";
let serverUrl = "https://server.urbiathlon.com";

if (process && process.env && process.env.NODE_ENV === "development") {
    graphqlServerUrl = "http://localhost:8907/graphql";
    serverUrl = "http://localhost:8907";
}

export const GRAPHQL_SERVER_URL = graphqlServerUrl;
export const SERVER_URL = serverUrl;
export const TINYMCE_IMAGE_URL = "Public/Images/TinyMCE";
export const TINYMCE_FILE_URL = "Public/Files/TinyMCE";
export const MARKETING_IMAGE_URL = "Public/Images/Marketing";
export const ARTICLE_IMAGE_URL = "Public/Images/Article";
export const COMPETITION_IMAGE_URL = "Public/Images/Competition";
export const COMPETITION_FILE_URL = "Public/Files/Competition";
export const ATHLETE_IMAGE_URL = "Public/Images/Athlete";
export const CONTENT_MANAGER_IMAGE_URL = "Public/Images/ContentManager";
export const BANNER_IMAGE_URL = "Public/Images/Banner";
export const ORGANISER_IMAGE_URL = "Public/Images/Organiser";
export const FEDERATION_IMAGE_URL = "Public/Images/Federation";
export const COUNTRY_IMAGE_URL = "Public/Images/Country";
export const CLUB_IMAGE_URL = "Public/Images/Club";
export const COMPETITION_SPONSOR_IMAGE_URL = "Public/Images/CompetitionSponsor";

//maximální velikost nahrávaného souboru (5Mb)
export const MAX_FILE_UPLOAD_SIZE = 5;
